import axios from 'axios'
import {
  Bar,
  ChartingLibraryWidgetOptions,
  HistoryMetadata,
} from '../charting_library/charting_library'
import moment from 'moment'

export const convertedInterval = (interval: string) => {
  switch (interval) {
    case '1':
      return '1min'
    case '5':
      return '5min'
    case '15':
      return '15min'
    case '30':
      return '30min'
    case '240':
    case '180':
    case '120':
    case '60':
      return '1h'
    case 'D':
      return 'day'
    case '1W':
      return 'day'
    case '1M':
      return 'day'
    default:
      return 'day'
  }
}

type ChartContainerProps = {
  load_last_chart: ChartingLibraryWidgetOptions['load_last_chart']
  interval: ChartingLibraryWidgetOptions['interval']
  timezone: ChartingLibraryWidgetOptions['timezone']
  header_widget_buttons_mode: ChartingLibraryWidgetOptions['header_widget_buttons_mode']
  theme: ChartingLibraryWidgetOptions['theme']
  library_path: ChartingLibraryWidgetOptions['library_path']
  charts_storage_url: ChartingLibraryWidgetOptions['charts_storage_url']
  charts_storage_api_version: ChartingLibraryWidgetOptions['charts_storage_api_version']
  client_id: ChartingLibraryWidgetOptions['client_id']
  user_id: ChartingLibraryWidgetOptions['user_id']
  fullscreen: ChartingLibraryWidgetOptions['fullscreen']
  autosize: ChartingLibraryWidgetOptions['autosize']
  studies_overrides: ChartingLibraryWidgetOptions['studies_overrides']
}

export const defaultChartWidgetOptions: ChartContainerProps = {
  load_last_chart: false,
  interval: 'D' as ChartingLibraryWidgetOptions['interval'],
  timezone: 'Asia/Ho_Chi_Minh',
  header_widget_buttons_mode: 'adaptive',
  theme: 'Dark',
  library_path: '/charting_library/',
  charts_storage_url: '',
  charts_storage_api_version: '1.1',
  client_id: 'tradingview.com',
  user_id: 'public_user_id',
  fullscreen: true,
  autosize: true,
  studies_overrides: {},
}

export const calculateIntervalTime = (
  firstDataRequest: boolean,
  from: number,
  to: number,
  periodRef: any,
  interval: string,
): { from: number; to: number } => {
  const convertedResolution = convertedInterval(interval)

  let calculateFrom = from
  let calculateTo = to

  if (convertedResolution === '1min' && firstDataRequest) {
    calculateFrom = moment().subtract(3, 'months').unix()
    calculateTo = moment().unix()
  }

  if (
    (convertedResolution === '5min' ||
      convertedResolution === '15min' ||
      convertedResolution === '30min' ||
      convertedResolution === '1h') &&
    firstDataRequest
  ) {
    calculateFrom = moment().subtract(6, 'months').unix()
    calculateTo = moment().unix()
  }

  if (convertedResolution === 'day') {
    if (firstDataRequest) {
      periodRef.current = {
        to: moment().unix(),
        from: moment().subtract('6', 'months').unix(),
      }
    }
    calculateFrom = periodRef.current.from
    calculateTo = periodRef.current.to

    periodRef.current = {
      to: periodRef.current.from,
      from: moment.unix(periodRef.current.from).subtract(6, 'months').unix(),
    }
  }

  return { from: calculateFrom, to: calculateTo }
}

export const symbolChartRequest = async (
  ticker: string,
  from: number,
  to: number,
  interval: string,
  onErrorCallback?: any,
): Promise<{ meta: HistoryMetadata; bars: Bar[] }> => {
  const { data: response } = await axios.get(
    `${
      process.env.REACT_APP_DATA_PLATFORM_API_URL
    }/trading-view/history?symbol=${ticker}&resolution=${convertedInterval(
      interval,
    )}&from=${from}&to=${to}`,
  )

  if (response.s !== 'ok' && response.s !== 'no_data') {
    if (onErrorCallback) onErrorCallback(response.errmsg)
    return {
      bars: [],
      meta: {
        noData: true,
      },
    }
  }

  const bars: Bar[] = []
  const meta: HistoryMetadata = {
    noData: false,
  }

  if (
    response.s === 'no_data' ||
    (response.s === 'ok' && response.t.length === 0)
  ) {
    meta.noData = true
  } else {
    const volumePresent = response.v !== undefined
    const ohlPresent = response.o !== undefined

    for (let i = 0; i < response.t.length; ++i) {
      const barValue: Bar = {
        time: response.t[i] * 1000,
        close: parseFloat(response.c[i]),
        open: parseFloat(response.c[i]),
        high: parseFloat(response.c[i]),
        low: parseFloat(response.c[i]),
      }

      if (ohlPresent) {
        barValue.open = parseFloat(response.o[i])
        barValue.high = parseFloat(response.h[i])
        barValue.low = parseFloat(response.l[i])
      }

      if (volumePresent) barValue.volume = parseFloat(response.v[i])

      bars.push(barValue)
    }
  }

  return { meta, bars }
}

export const checkExternalFeatureParams = (
  searchParams: any,
  widgetOptions: any,
) => {
  if (searchParams.get('hideSetting')?.toString() === 'true')
    widgetOptions.disabled_features?.push('header_settings')

  if (
    searchParams.get('custom-fullscreen') ||
    searchParams.get('hide-fullscreen')
  ) {
    widgetOptions.disabled_features?.push('header_fullscreen_button')
  }

  if (searchParams.get('hide-header-widget'))
    widgetOptions.disabled_features?.push('header_widget')

  if (searchParams.get('hide-left-toolbar'))
    widgetOptions.disabled_features?.push('left_toolbar')

  if (searchParams.get('hide-time-frames'))
    widgetOptions.disabled_features?.push('timeframes_toolbar')

  return widgetOptions
}

export const addCustomFullScreenButton = (
  tvWidget: any,
  key = 'FULL_SCREEN_MODE',
) => {
  const button = tvWidget.createButton({ align: 'right' } as any)
  button.style.cursor = 'pointer'
  button.addEventListener('click', () => {
    window.parent.postMessage(
      JSON.stringify({ site: 'DATX', message: key }),
      '*',
    )
  })
  button.innerHTML =
    '<span class="icon-2Vpz_LXc"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor"><path d="M21 7v4h1V6h-5v1z"></path><path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path><path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path><path d="M16.854 16.146l5 5-.708.708-5-5z"></path><g><path d="M7 21v-4H6v5h5v-1z"></path><path d="M11.146 16.146l-5 5 .708.708 5-5z"></path></g></g></svg></span>'
}

type OptionType = {
  significantDigits: number
  thousandsSeparator: string
  decimalSeparator: string
  symbol: string
}

export const currencyFormatter = (
  defaultValue: number,
  options: OptionType = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '',
  },
) => {
  if (typeof defaultValue !== 'number') defaultValue = 0.0

  let value = defaultValue.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')

  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator,
  )}${Number(decimal) > 0 ? options.decimalSeparator : ''}${
    Number(decimal) > 0 ? decimal : ''
  }`
}
