import { Ref, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import DATAFEED from './datafeed'
import moment from 'moment'
import {
  addCustomFullScreenButton,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../function'
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  LanguageCode,
  ResolutionString,
  widget,
} from '../../charting_library'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { GET_DERIVATIVES_RECOMMENDATION } from '../../graphQL/queries/recommendation'
import { SUB_DERIVATIVE_RECOMMENDATION } from '../../graphQL/subscription/recommendation'

const ChartContainer = () => {
  const socketIo = useRef<any>(null)
  const recommendationSocketIo = useRef<any>(null)

  const ref: Ref<any> = useRef()
  const widgetRef = useRef<IChartingLibraryWidget>()
  const [searchParams, _] = useSearchParams()
  const symbol = searchParams.get('symbol')?.toUpperCase() as string
  const periodRef = useRef({
    to: moment().unix(),
    from: moment().subtract('6', 'months').unix(),
  })

  const [getRecommendation] = useLazyQuery(GET_DERIVATIVES_RECOMMENDATION)

  useSubscription(SUB_DERIVATIVE_RECOMMENDATION, {
    onData: async ({ data: latestDataRc }) => {
      if (
        latestDataRc?.data?.derivativesRecommendationLatest?.ticker === symbol
      ) {
        widgetRef.current?.chart()?.clearMarks()
        widgetRef.current?.chart()?.refreshMarks()
      }
    },
  })

  useEffect(() => {
    if (!ref.current) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      interval: '15' as ResolutionString,
      symbol,
      datafeed: DATAFEED({
        widgetRef,
        periodRef,
        socketIo,
        recommendationSocketIo,
        getRecommendation,
      }),
      container: ref.current,
      locale: (searchParams.get('language') as LanguageCode) || 'vi',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
      ],
      enabled_features: [
        'study_templates',
        'pinch_scale',
        'show_zoom_and_move_buttons_on_touch',
        'vert_touch_drag_scroll',
        'horz_touch_drag_scroll',
      ],
      timeframe: '3m',
      time_frames: [
        {
          text: '2y',
          resolution: '1W' as ChartingLibraryWidgetOptions['interval'],
          description: '2 Years',
          title: '2Y',
        },
        {
          text: '1y',
          resolution: '1W' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Years',
          title: '1Y',
        },
        {
          text: '6m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '6 Months',
          title: '6M',
        },
        {
          text: '3m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '3 Months',
          title: '3M',
        },
        {
          text: '1m',
          resolution: '1D' as ChartingLibraryWidgetOptions['interval'],
          description: '1 Months',
          title: '1M',
        },
      ],
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      widgetRef.current = tvWidget

      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget)
    })

    return () => {
      if (tvWidget) tvWidget.remove()
      socketIo.current?.close()
    }
  }, [searchParams])

  return <div ref={ref} style={{ height: '100%' }} />
}

export default ChartContainer
