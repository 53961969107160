import { Ref, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import _sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'

import {
  ChartingLibraryWidgetOptions,
  LanguageCode,
  widget,
} from '../../charting_library'
import {
  addCustomFullScreenButton,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../function'

type Props = {
  datafeedUrl?: string
}

const ChartContainer: React.FC<Props> = ({ datafeedUrl }) => {
  const ref: Ref<any> = useRef()
  const [searchParams, _] = useSearchParams()

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      symbol: searchParams.get('symbol') as string,
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
        datafeedUrl || (process.env.REACT_APP_API_URL as string),
      ),
      theme: 'Dark',
      container: ref.current,
      locale: (searchParams.get('language') as LanguageCode) || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      timezone: 'Asia/Ho_Chi_Minh',
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget)
    })

    return () => {
      if (tvWidget) {
        tvWidget.remove()
      }
    }
  }, [searchParams])

  return (
    <div ref={ref} className={'TVChartContainer'} style={{ height: '100vh' }} />
  )
}
ChartContainer.propTypes = {
  datafeedUrl: PropTypes.string,
}
export default ChartContainer
