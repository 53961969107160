import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SymbolChart from './pages/SymbolChart'
import ExchangeChart from './pages/ExchangeChart'
import GoldenCross from './pages/GoldenCross'
import SectorRank from './pages/SectorRank'
import Recommendation from './pages/Recommendation'
import StockRank from './pages/StockRank'
import DerivativeRecommendation from './pages/DerivativeRecommendation'
import WatchList from './pages/WatchList'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './graphQL/config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SymbolChart />} />
        <Route path="/exchange" element={<ExchangeChart />} />
        <Route path="/golden-cross" element={<GoldenCross />} />
        <Route path="/sector-rank" element={<SectorRank />} />
        <Route path="/stock-rank" element={<StockRank />} />
        <Route path="/recommendation" element={<Recommendation />} />
        <Route path="/watch-list" element={<WatchList />} />
        <Route
          path="/derivative-recommendation"
          element={<DerivativeRecommendation />}
        />
      </Routes>
    </BrowserRouter>
  </ApolloProvider>,
)

reportWebVitals()
