import { Ref, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'

import {
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
  widget,
} from '../../charting_library'
import DATAFEED from './datafeed'
import {
  addCustomFullScreenButton,
  checkExternalFeatureParams,
  defaultChartWidgetOptions,
} from '../function'

const ChartContainer = () => {
  const ref: Ref<any> = useRef()
  const [searchParams, _] = useSearchParams()
  const socketIo = useRef<any>(null)
  useEffect(() => {
    if (!ref.current) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      ...defaultChartWidgetOptions,
      interval: '15' as ResolutionString,
      symbol: searchParams.get('symbol')?.toUpperCase() as string,
      datafeed: DATAFEED({
        indicator: searchParams.get('indicator') || 'ma',
        socketIo,
      }),
      container: ref.current,
      locale: (searchParams.get('language') as LanguageCode) || 'vi',
      disabled_features: [
        'use_localstorage_for_settings',
        'symbol_search_hot_key',
        'header_symbol_search',
      ],
      enabled_features: ['study_templates'],
      timeframe: '6M',
    }

    const tvWidget = new widget(
      checkExternalFeatureParams(searchParams, widgetOptions),
    )

    tvWidget.headerReady().then(() => {
      if (searchParams.get('custom-fullscreen'))
        addCustomFullScreenButton(tvWidget)
    })

    tvWidget.onChartReady(() => {
      tvWidget.chart().setChartType(2)
      tvWidget.chart().removeAllStudies()
      tvWidget.chart().getSeries().setChartStyleProperties(2, {
        color: '#01A612',
      })
    })

    return () => {
      if (tvWidget) tvWidget.remove()
      socketIo.current?.close()
    }
  }, [searchParams])

  return (
    <div ref={ref} className="TVChartContainer" style={{ height: '100%' }} />
  )
}
ChartContainer.propTypes = {
  datafeedUrl: PropTypes.string,
}
export default ChartContainer
