import ChartContainer from '../components/ChartContainer'

const ExchangeChart = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <ChartContainer datafeedUrl={`${process.env.REACT_APP_API_URL}/index`} />
    </div>
  )
}

export default ExchangeChart
